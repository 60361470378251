import React, { useEffect } from "react"
 
function Privacy(props) {
  useEffect(() => {
    document.title = "Privacy - Scooba"
  }, []);

  return (
    <div className="App">
      <header>
        <h1 className="App-Text">Privacy Policy</h1>
      </header>
      <p className="App-Text">Coming Soon</p>
    </div>
  )
}
 
export default Privacy;