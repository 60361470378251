// Import the BrowserRouter, Route and Link components
import { BrowserRouter, Route } from 'react-router-dom';
import Scooba from './components/Scooba.js';
import Privacy from './components/Privacy.js';
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Route exact path="/" component={Scooba} />
        <Route exact path="/privacy" component={Privacy}/>
      </div>
    </BrowserRouter>
  );
}

export default App;
